@charset "UTF-8";
/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "~jsgantt-improved/dist/jsgantt.css";
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}
.toast-container .ngx-toastr {
  border-radius: 0.25rem;
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 0.75rem 1.25rem 0.75rem 50px;
  width: 300px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.03);
  pointer-events: auto;
}
.toast-container .ngx-toastr .toast-title {
  font-weight: bold;
}
.toast-container .ngx-toastr .toast-message {
  word-wrap: break-word;
}
.toast-container .ngx-toastr .toast-message a:hover {
  text-decoration: underline;
}
.toast-container .ngx-toastr .toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: inherit;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  background: transparent;
  border: 0;
  padding: 0;
}
.toast-container .ngx-toastr .toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.3;
}
.toast-container .ngx-toastr .toast-close-button:hover,
.toast-container .ngx-toastr .toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.75;
}
.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  opacity: 1;
  cursor: pointer;
}

.toast-success {
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/solid/check.svg */
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgba(21, 87, 36, 0.999999)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
  color: #155724;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
}

.toast-error {
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/solid/times-circle.svg */
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgba(114, 28, 36, 0.999999)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
}

.toast-info {
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/solid/info-circle.svg */
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgba(12, 84, 96, 0.999999)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
  color: #0c5460;
  background-color: #d1ecf1;
  border: 1px solid #bee5eb;
}

.toast-warning {
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/solid/exclamation-triangle.svg */
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgba(133, 100, 4, 0.999999)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
  color: #856404;
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
}

@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
body {
  padding: 0;
  margin: 0;
  float: left;
  width: 100%;
}
body .disable {
  pointer-events: none;
  color: #ccc;
}
body .text-red {
  color: red;
}
body .card-title {
  padding-bottom: 15px;
  border-bottom: 1px solid #ececec;
}
body .table-responsive {
  overflow: auto;
  max-width: 100% !important;
}
body .table-responsive td {
  padding: 8px 10px !important;
  vertical-align: middle;
}
body .modal.fade.show {
  z-index: 1500;
}
body .modal.show .modal-dialog {
  z-index: 1000;
}
body .app-sidebar {
  z-index: 5;
}
body .spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}
body .modal-dialog {
  margin-top: 60px;
}
@media (min-width: 992px) {
  body .modal-big {
    max-width: 80%;
  }
}
@media (min-width: 992px) {
  body .modal-big {
    max-width: 1200px;
  }
}
@media (min-width: 1100px) {
  body .modal-big {
    max-width: 1350px;
  }
}
@media (min-width: 1250px) {
  body .modal-big {
    max-width: 1400px;
  }
}

.modal.fade {
  background: rgba(0, 0, 0, 0.74);
}

.toast-message, .toast-title {
  color: #000;
}

.accordion .card {
  border-radius: 4px !important;
  margin-bottom: 10px;
  background: rgba(56, 85, 179, 0.16) !important;
}
.accordion .card .card-body {
  padding: 10px !important;
}
.accordion .card span {
  color: #3855B3 !important;
}
.accordion .card .float-right {
  padding-top: 4px;
}

.d-flex {
  display: flex;
}
.d-flex.d-right {
  justify-content: right;
}
.d-flex.bet {
  justify-content: space-between;
}
.d-flex.end {
  justify-content: flex-end;
}
.d-flex.middle {
  align-items: center;
}
.d-flex.center {
  justify-content: center;
}
.d-flex.bottom {
  align-items: flex-end;
}

.ag-root-wrapper-body .ag-header-cell .ag-wrapper input {
  border: 1px solid #ccc;
  opacity: 1;
  border-radius: 4px;
}
.ag-root-wrapper-body .ag-header-cell .ag-wrapper:after {
  content: "";
}
.ag-root-wrapper-body .ag-header-cell .ag-wrapper.ag-checked::after {
  content: "";
  font-family: "FontAwesome" !important;
}
.ag-root-wrapper-body .ag-cell .ag-wrapper input {
  border: 1px solid #ccc;
  opacity: 1;
  border-radius: 4px;
}
.ag-root-wrapper-body .ag-cell .ag-wrapper:after {
  content: "";
}
.ag-root-wrapper-body .ag-cell .ag-wrapper.ag-checked::after {
  content: "";
  font-family: "FontAwesome" !important;
}

.wrap-btn-cell {
  gap: 10px;
  display: flex;
  font-size: 18px;
  justify-content: center;
}
.wrap-btn-cell span {
  cursor: pointer;
}

.input-date {
  position: relative;
}
.input-date input {
  padding-right: 40px;
}
.input-date button {
  position: absolute;
  right: 0px;
  top: 0px;
  margin: 0px;
  border: none;
  cursor: pointer;
}

.p-20 {
  padding: 20px;
}

.modal-header .modal-title {
  color: #ffffff;
}

.fields input {
  min-width: 0px;
  width: 100%;
}

.wrap-form {
  padding: 20px;
}

.fields label {
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #565758;
  display: inline-block;
  font-family: "Roboto-Bold" !important;
  margin-bottom: 0px;
}
.fields input {
  background-color: #fff !important;
  border: 1px solid #E4E4E4;
  border-radius: 4px;
  font-size: 12px;
  padding-left: 10px;
  width: 100%;
  height: 36px;
}

.tb-h700 {
  max-height: 600px;
  overflow-x: auto;
}
.tb-h700 table {
  margin-left: 0px !important;
}
.tb-h700 table thead {
  position: sticky;
  top: -2px;
}

ng-select.ng-select-disabled .ng-select-container {
  background: #e9ecef !important;
}

.ag-header .ag-header-container {
  color: #23314b;
  font-size: 14px;
  border: none;
  font-weight: 600;
  background: #e0e0e0;
}

.ag-theme-alpine .ag-icon {
  font-family: "agGridAlpine" !important;
}

.ag-cell-value app-picker-cell-editor input {
  width: calc(100% - 39px);
  height: 100%;
  border: 1px solid #ccc;
}

.can-edit {
  border: 1px solid #ccc !important;
  border-radius: 4px;
}

div.pagination {
  gap: 10px;
  margin-top: 10px;
  align-items: center;
}
div.pagination pagination {
  margin-top: 0px;
}
div.pagination pagination .pagination {
  margin-bottom: 0px;
  margin-top: 0px;
}

.wrap-edit-cell {
  display: flex;
  height: 100%;
}

.date-edit {
  overflow: visible !important;
  border: 1px solid #ccc !important;
  border-radius: 4px;
}

.color-green {
  color: #4CAF50;
}

.color-green {
  color: #4CAF50;
}

.color-blue {
  color: #3855B3;
}

.table-grid {
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
  margin-bottom: 20px !important;
  background: #f2f5f7 !important;
  position: relative;
}
.table-grid .no-data-text {
  position: absolute;
  top: 48px;
  left: 0;
  background: #fff;
  width: 100%;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.table-grid .no-data-text.a-head {
  top: 100px;
}

.table-grid .ag-root-wrapper {
  border: none;
}

.ag-theme-alpine .ag-row.ag-row-odd {
  background: #f2f5f7;
}

.ag-theme-alpine .ag-row-selected {
  background-color: rgba(33, 150, 243, 0.3) !important;
}

.table-grid .ag-theme-alpine .ag-layout-auto-height .ag-center-cols-clipper {
  min-height: 40px;
}

.filter-boolean {
  padding: 10px;
}
.filter-boolean label {
  margin-bottom: 5px;
  display: block;
}

.table-grid .ag-cell-label-container .ag-header-icon {
  opacity: 1;
  cursor: pointer;
}
.table-grid .ag-cell-label-container .ag-header-icon .ag-icon {
  font-size: 20px;
}
.table-grid .ag-cell-label-container .ag-header-icon .ag-icon::before {
  content: "";
}

.table-grid [col-id=fdsfd] .ag-icon-filter {
  opacity: 0;
}

.cell-f-center .ag-cell-wrapper {
  justify-content: center;
}

table.table > tbody > tr > td.td-level-2 {
  padding-left: 20px !important;
}
table.table > tbody > tr > td.td-level-3 {
  padding-left: 40px !important;
}
table.table > tbody > tr > td.td-level-4 {
  padding-left: 60px !important;
}
table.table > tbody > tr > td.td-level-5 {
  padding-left: 80px !important;
}
table.table > tbody > tr > td.td-level-6 {
  padding-left: 100px !important;
}

.font-bold td {
  font-weight: bold !important;
}

.model-normal {
  width: 550px !important;
}

button.btn {
  cursor: pointer;
}

.wapper-filter {
  font-size: 14px;
  color: #000000;
  padding-top: 15px;
  padding-bottom: 15px;
}
.wapper-filter .row {
  margin-left: -5px;
  margin-right: -5px;
}
.wapper-filter .col-1, .wapper-filter .col-2, .wapper-filter .col-3, .wapper-filter .col-4, .wapper-filter .col-5, .wapper-filter .col-6, .wapper-filter .col-7, .wapper-filter .col-8, .wapper-filter .col-9, .wapper-filter .col-10, .wapper-filter .col-11, .wapper-filter .col-12, .wapper-filter .col, .wapper-filter .col-auto, .wapper-filter .col-sm-1, .wapper-filter .col-sm-2, .wapper-filter .col-sm-3, .wapper-filter .col-sm-4, .wapper-filter .col-sm-5, .wapper-filter .col-sm-6, .wapper-filter .col-sm-7, .wapper-filter .col-sm-8, .wapper-filter .col-sm-9, .wapper-filter .col-sm-10, .wapper-filter .col-sm-11, .wapper-filter .col-sm-12, .wapper-filter .col-sm, .wapper-filter .col-sm-auto, .wapper-filter .col-md-1, .wapper-filter .col-md-2, .wapper-filter .col-md-3, .wapper-filter .col-md-4, .wapper-filter .col-md-5, .wapper-filter .col-md-6, .wapper-filter .col-md-7, .wapper-filter .col-md-8, .wapper-filter .col-md-9, .wapper-filter .col-md-10, .wapper-filter .col-md-11, .wapper-filter .col-md-12, .wapper-filter .col-md, .wapper-filter .col-md-auto, .wapper-filter .col-lg-1, .wapper-filter .col-lg-2, .wapper-filter .col-lg-3, .wapper-filter .col-lg-4, .wapper-filter .col-lg-5, .wapper-filter .col-lg-6, .wapper-filter .col-lg-7, .wapper-filter .col-lg-8, .wapper-filter .col-lg-9, .wapper-filter .col-lg-10, .wapper-filter .col-lg-11, .wapper-filter .col-lg-12, .wapper-filter .col-lg, .wapper-filter .col-lg-auto, .wapper-filter .col-xl-1, .wapper-filter .col-xl-2, .wapper-filter .col-xl-3, .wapper-filter .col-xl-4, .wapper-filter .col-xl-5, .wapper-filter .col-xl-6, .wapper-filter .col-xl-7, .wapper-filter .col-xl-8, .wapper-filter .col-xl-9, .wapper-filter .col-xl-10, .wapper-filter .col-xl-11, .wapper-filter .col-xl-12, .wapper-filter .col-xl, .wapper-filter .col-xl-auto {
  padding-right: 5px !important;
  padding-left: 5px !important;
}
.wapper-filter label {
  padding-top: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.wapper-detail-report {
  width: 100%;
  float: left;
  position: relative;
  background: #9ad2db;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.wapper-detail-report ul {
  width: 100%;
  padding: 10px;
  margin: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  float: left;
  display: block;
}
.wapper-detail-report ul:last-child {
  border: none;
}
.wapper-detail-report ul li {
  float: left;
  list-style: none;
  color: #333e40;
  padding: 0;
  margin: 0;
}
.wapper-detail-report ul li:first-child {
  width: 28%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.wapper-detail-report ul li:last-child {
  width: 72%;
}

app-storage-acceptance-tree .form-group ngx-treeview-item label {
  text-transform: none;
  font-weight: 500;
  font-size: 16px;
}
app-storage-acceptance-tree .color-blue {
  color: blue !important;
}
app-storage-acceptance-tree .color-red {
  color: red !important;
}

.bs-modal-tree.modal-footer {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 999;
}

.imgsss {
  margin: auto;
  position: relative;
  height: 100vh;
  width: auto;
  max-width: 800px;
}
.imgsss:after {
  content: "";
  padding-top: 56%;
  display: block;
}
.imgsss img {
  position: absolute;
  top: 50%;
  left: 50%;
  -o-object-fit: contain;
  object-fit: contain;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
}
.imgsss video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  height: 90%;
  -o-object-fit: contain;
  object-fit: contain;
  transform: translate(-50%, -50%);
}

.popup-image-slider .carousel-control-prev,
.popup-image-slider .carousel-control-next {
  width: auto;
  padding: 20px;
}

.tree-media .treeview-container {
  max-height: 100% !important;
  overflow: auto !important;
}

app-storage-acceptance-tree ngx-treeview .treeview-header {
  max-width: 400px;
}

.tree-folder-wrap {
  position: relative;
}
.tree-folder-wrap > .wapper-filter {
  width: 75%;
  float: right;
}
.tree-folder-wrap .wrap-tree {
  width: 100%;
}
.tree-folder-wrap .wrap-tree .col-12 {
  position: static;
}
.tree-folder-wrap .wrap-tree .treeview-header {
  width: 25%;
  position: absolute;
  left: 0px;
  top: 15px;
}
.tree-folder-wrap .wrap-tree .treeview-header .dropdown-divider {
  display: none;
}
.tree-folder-wrap .wrap-tree .treeview-header > .row-filter > .col-12 {
  padding-left: 6px;
}

.dataGantt .gchartcontainer {
  height: 300px;
}

.gtaskheading, .gmajorheading, .gminorheading {
  min-width: 64px;
  width: 50px !important;
  white-space: break-spaces;
}

.name-image span:first-child {
  display: block;
}
.name-image span:last-child {
  display: none;
}
.name-image:hover span:first-child {
  display: none;
}
.name-image:hover span:last-child {
  display: block;
}