/* Sample CSS for jsGanttImproved v1.8.0 */

div.gantt {
  font-family: tahoma, arial, verdana, Sans-serif;
  font-size: 12px;
  color: #656565;
}

.gantt table {
  border-collapse: collapse;
}

.gantt td {
  padding: 0px;
}

/* cell defaults */
.headweekends div {
  font-size: 8px;
  width: 100% !important;
  margin: 0;
}

.gmajorheading,
.gminorheading,
.gminorheadingwkend,
.gtaskcell,
.gtaskcellcurrent,
.gtaskcellwkend {
  height: 19px;
  font-size: 12px;
  border: #efefef 1px solid;
  text-align: center;
  cursor: default;
}

.gtasklist {
  height: 19px;
  min-width: 5px;
  max-width: 5px;
  width: 5px;
  border: #efefef 1px solid;
  border-right: none;
}

.gtasknolist-label {
  padding: 10px 50px;
}
/* all three width values set just to make sure - helps resizing code */

/* Additional values for some cell elements */

.gtaskheading,
.gmajorheading,
.gminorheading {
  background-color: #ffffff;
  font-weight: bold;
  font-size: 11px;
  white-space: nowrap;
}

.gtaskcellwkend,
.gtaskcellcurrent,
.gminorheadingwkend {
  background-color: #f7f7f7;
  font-weight: bold;
  font-size: 9px;
  white-space: nowrap;
}

.gtaskcellcurrent {
  background-color: #e1e0f7;
}

td.gtaskcell {
  text-align: left;
}

td.gspanning {
  border-left: none;
  border-right: none;
}

.gtaskcelldiv {
  position: relative;
}

/* Task list defaults */

.gtaskheading,
.gname,
.gtaskname,
.gres,
.gdur,
.gcomp,
.gstartdate,
.gplanstartdate,
.gplanenddate,
.gcost,
.genddate {
  height: 18px;
  white-space: nowrap;
  border: #efefef 1px solid;
}

.gtaskheading div,
.gname div,
.gtaskname div,
.gres div,
.gdur div,
.gcomp div,
.gstartdate div,
.gplanstartdate div,
.gplanenddate div,
.gcost div,
.genddate div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.gtaskbarcontainer.gplan {
  position: absolute !important;
  top: 0px;
  opacity: 0.3;
  background: white;
  z-index: 10000;
  border: 1px solid black;
}

/* Additional values for some task list elements */

/* needed for IE8 */

.gstartdate div,
.gplanstartdate,
.gplanenddate,
.gstartdate,
.genddate {
  text-align: center;
  min-width: 105px;
  width: 105px;
  max-width: 105px;
  font-size: 10px;
}

.gtaskheading {
  text-align: center;
}

.gtaskname div,
/* needed for IE8 */

.gtaskname {
  min-width: 170px;
  max-width: 220px;
  width: 220px;
  font-size: 12px;
  border-left: none;
}

.gtaskheading,
.gtaskname {
  text-overflow: ellipsis;
  overflow: hidden;
}

.gtaskname.gtaskeditable div,
.gtaskname.gtaskeditable {
  overflow: unset;
}

.gselector {
  text-align: left;
  white-space: nowrap;
  min-width: 170px;
  max-width: 170px;
  width: 170px;
}

.gformlabel {
  position: relative;
  top: 0px;
  cursor: pointer;
  border: #ffffff 1px solid;
  margin-left: 2px;
  padding-left: 2px;
  padding-right: 2px;
}

span.gformlabel:hover,
span.gselected {
  background-color: #dbecff;
  border: #cccccc 1px solid;
}

span.gfoldercollapse {
  color: #000000;
  cursor: pointer;
  font-weight: bold;
  font-size: 12px;
  font-family: Courier, "Courier New", monospace;
}

.gtasktableh,
.gtasktable {
  border-right: #efefef 1px solid;
  width: 100%;
}

.gcharttableh,
.gtasktableh,
.gtasktable,
.gcharttable {
  border-collapse: collapse;
}

.gcharttable,
.gcharttableh {
  table-layout: fixed;
}

.gcharttable {
  border: #efefef 1px solid;
}

/* for some reason firefox needs this */

/* Differentiate Group, Milestone and Ordinary task items (applied to row) */

.ggroupitem {
  background-color: #fbfbfb;
  font-weight: bold;
}

.gmileitem,
.glineitem {
  background-color: #ffffff;
}

/* highlight row (applied to row) */

.gitemhighlight td {
  background-image: none;
  background-color: #fffde5;
}

.gitemdifferent td {
  background-image: none;
  background-color: rgba(0, 0, 0, 0.05);
}

/* task bar caption text styles */

.gmilecaption,
.ggroupcaption,
.gcaption {
  font-weight: normal;
  font-size: 9px;
  text-align: left;
  white-space: nowrap;
  top: 1px;
  position: absolute;
  top: 2px;
}

.ggroupcaption,
.gcaption {
  right: -126px;
}

/* Task complete %age bar shared attributes */

.gtaskcomplete {
  float: left;
  overflow: hidden;
}

/* Task complete %age bar */

.gtaskcomplete {
  height: 5px;
  background-color: #000000;
  margin-top: 4px;
  opacity: 0.4;
  filter: alpha(opacity=40);
}

/* Milestones */

.gmilestone {
  font-size: 14px;
  position: absolute;
  top: -2px;
}

.gmdtop {
  top: 2px;
  overflow: hidden;
  width: 0px;
  height: 0px;
  border-bottom: 5px solid black;
  border-left: 5px solid transparent;
  border-top: 5px solid transparent;
  border-right: 5px solid transparent;
}

.gmdbottom {
  top: 2px;
  overflow: hidden;
  width: 0px;
  height: 0px;
  border-top: 5px solid black;
  border-left: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid transparent;
}

/* Task bar shared attributes */

.ggroupblack,
.gtaskblue,
.gtaskred,
.gtaskgreen,
.gtaskyellow,
.gtaskpurple,
.gtaskpink {
  height: 13px;
  filter: alpha(opacity=90);
  opacity: 0.9;
  margin-top: 1px;
}

/* Task bars - ggroupblack is set as the default class on the task if it is undefined */

.ggroupblack {
  height: 7px;
  background: #000000;
  margin-top: 2px;
}

.ggroupblackendpointleft {
  overflow: hidden;
  width: 0px;
  height: 0px;
  top: 2px;
  border-top: 4px solid black;
  border-left: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-right: 4px solid transparent;
  float: left;
}

.ggroupblackendpointright {
  overflow: hidden;
  width: 0px;
  height: 0px;
  top: 2px;
  border-top: 4px solid black;
  border-left: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-right: 4px solid transparent;
  float: right;
}

.ggroupblackcomplete {
  float: left;
  overflow: hidden;
  height: 3px;
  filter: alpha(opacity=80);
  opacity: 0.8;
  background-color: #777777;
  margin-top: 2px;
  margin-bottom: 2px;
}

.gtaskblue {
  background: rgb(58, 132, 195);
  /* Old browsers */
  background: linear-gradient(to bottom, rgba(58, 132, 195, 1) 0%, rgba(65, 154, 214, 1) 20%, rgba(75, 184, 240, 1) 40%, rgba(58, 139, 194, 1) 70%, rgba(38, 85, 139, 1) 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4bb8f0', endColorstr='#3a84c3', GradientType=0);
  /* IE6-9 */
}

.gtaskred {
  background: rgb(196, 58, 58);
  /* Old browsers */
  background: linear-gradient(to bottom, rgba(196, 58, 58, 1) 0%, rgba(211, 65, 65, 1) 20%, rgba(239, 76, 76, 1) 40%, rgba(196, 58, 58, 1) 70%, rgba(135, 37, 37, 1) 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ef4c4c', endColorstr='#c43a3a', GradientType=0);
  /* IE6-9 */
}

.gtaskgreen {
  background: rgb(80, 193, 58);
  /* Old browsers */
  background: linear-gradient(to bottom, rgba(80, 193, 58, 1) 0%, rgba(88, 209, 64, 1) 20%, rgba(102, 237, 75, 1) 40%, rgba(80, 193, 58, 1) 70%, rgba(53, 132, 37, 1) 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#66ED4B', endColorstr='#50c13a', GradientType=0);
  /* IE6-9 */
}

.gtaskyellow {
  background: rgb(247, 228, 56);
  /* Old browsers */
  background: linear-gradient(to bottom, rgba(247, 228, 56, 1) 0%, rgba(239, 239, 55, 1) 20%, rgba(255, 255, 58, 1) 40%, rgba(242, 236, 55, 1) 70%, rgba(241, 218, 54, 1) 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffff3a', endColorstr='#f7e438', GradientType=0);
  /* IE6-9 */
}

.gtaskpurple {
  background: rgb(193, 58, 193);
  /* Old browsers */
  background: linear-gradient(to bottom, rgba(193, 58, 193, 1) 0%, rgba(211, 65, 211, 1) 20%, rgba(239, 76, 239, 1) 40%, rgba(193, 58, 193, 1) 70%, rgba(137, 38, 137, 1) 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ef4cef', endColorstr='#892689', GradientType=0);
  /* IE6-9 */
}

.gtaskpink {
  background: rgb(249, 177, 245);
  /* Old browsers */
  background: linear-gradient(to bottom, rgba(249, 177, 245, 1) 0%, rgba(247, 192, 243, 1) 20%, rgba(247, 202, 244, 1) 40%, rgba(249, 192, 246, 1) 70%, rgba(252, 174, 247, 1) 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f7caf4', endColorstr='#fcaef7', GradientType=0);
  /* IE6-9 */
}

.gtaskbluecomplete,
.gtaskredcomplete,
.gtaskgreencomplete,
.gtaskyellowcomplete,
.gtaskpurplecomplete,
.gtaskpinkcomplete {
  float: left;
  overflow: hidden;
  height: 5px;
  filter: alpha(opacity=40);
  opacity: 0.4;
  background-color: #000000;
  margin-top: 4px;
}

/*  END Task bar styles */

.glinev {
  border-left: 1px solid;
  width: 0px;
}

.glineh {
  border-top: 1px solid;
  height: 0px;
}

.gDepFS,
.gDepSS,
.gDepSF,
.gDepFF {
  border-color: #ff0000;
}

.gDepFSArw,
.gDepSSArw {
  overflow: hidden;
  width: 0px;
  height: 0px;
  border-bottom: 4px solid transparent;
  border-left: 4px solid #ff0000;
  border-top: 4px solid transparent;
  border-right: 4px solid transparent;
}

.gDepFFArw,
.gDepSFArw {
  overflow: hidden;
  width: 0px;
  height: 0px;
  border-bottom: 4px solid transparent;
  border-left: 4px solid transparent;
  border-top: 4px solid transparent;
  border-right: 4px solid #ff0000;
}

.gCurDate {
  border-color: #0000ff;
}

div.gtaskbarcontainer {
  z-index: 1;
  position: absolute;
  top: 0px;
}

.textbar {
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 40%;
  display: inline-block;
}

.JSGanttToolTip {
  position: absolute;
  display: block;
  z-index: 10000;
}

.JSGanttToolTipcont {
  font-family: tahoma, arial, verdana;
  font-size: 10px;
  display: block;
  background: #ffffff;
  color: #656565;
}

.gTaskInfo {
  background: #dbecff;
  width: 400px;
  border: #656565 1px solid;
  border-radius: 10px;
  padding: 4px 6px 4px 6px;
  float: left;
}

.gTtTitle {
  display: block;
  font-size: 12px;
  font-weight: bold;
  color: #404040;
  margin-left: 4px;
  margin-bottom: 1em;
}

.gTaskLabel {
  font-size: 11px;
  font-weight: bold;
  color: #656565;
  margin-left: 4px;
}

.gTaskText {
  position: absolute;
  left: 90px;
  padding-top: 1px;
  font-size: 10px;
  font-weight: normal;
  color: #656565;
}

.gTaskNotes {
  font-size: 11px;
  font-weight: normal;
  color: #323232;
  padding: 0 15px;
  display: block;
}

.gTIn {
  padding-top: 10px;
}

.gantt {
  min-width: 632px;
  /* 2x LC width */
}

.gchartcontainer {
  /* padding-left: 532px; LC width */
  line-height: 1;
  /* Overrides inherited CSS (e.g. from Bootstrap) */
  display: flex;
}

.gcontainercol {
  position: relative;
}

/* Add a max-height value here if wanted */

.glistgrid {
  min-width: 132px;
  /* LC width */
  float: left;
  /* LC width */
  padding-right: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
  background-color: #ffffff;
  overflow: hidden;
}

.glistlbl {
  min-width: 132px;
  /* LC width */
  float: left;
  /* LC width */
  padding-right: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
  background-color: #ffffff;
  overflow: hidden;
  flex: none;
}

.glistlbl,
.gchartgrid {
  display: flex;
  flex-direction: column;
}

.glabelfooter {
  clear: both;
}

.ggridfooter {
  clear: both;
}

.rhscrpad {
  width: 150px;
  position: absolute;
  top: 0px;
  height: 1px;
}

.gchartgrid {
  padding-right: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
  background-color: #ffffff;
  position: relative;
  /* overflow: auto; */
  overflow: unset;
  overflow-y: auto;
  min-height: 0%;
}

.gchartlbl {
  padding-right: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
  flex: none;
}

/* Old Internet Explorer version hacks */

.gantt {
  _height: 100%;
}

/* otherwise the chart disappears! */

div .gantt {
  /* _width: 1064px; ie6 fixed width */
}

div.gchartlbl,
div.gchartgrid {
  /* _width: 532px; */
}

/* ie6 fixed width */

div.glistlbl,
div.glistgrid {
  *right: 0px;
  /* ie7 pulls the content too far left with the negative margin */
  _right: 532px;
  /* but ie6 fixed width needs this */
  _margin-left: -532px;
  /* ie6 fixed width */
  float: left;
}

div.gchartgrid {
  *padding-bottom: 20px;
  *overflow-y: hidden;
}

/* variable height design, no need for vertical scroll */

td.gmajorheading div {
  *overflow: hidden;
}

/* stops resizing fixed width columns if the text is too wide */

td.gspanning div {
  *overflow: hidden;
}

/* stops resizing fixed width columns if the text is too wide */

/* border transparency tricks */

.ggroupblackendpointleft {
  _border-top: 4px solid black;
  _border-left: 4px solid pink;
  _border-bottom: 4px solid pink;
  _border-right: 4px solid pink;
  _filter: chroma(color=pink);
}

.ggroupblackendpointright {
  _border-top: 4px solid black;
  _border-left: 4px solid pink;
  _border-bottom: 4px solid pink;
  _border-right: 4px solid pink;
  _filter: chroma(color=pink);
}

.gmdtop {
  _border-left: 5px solid pink;
  _border-top: 5px solid pink;
  _border-right: 5px solid pink;
  _filter: chroma(color=pink);
}

.gmdbottom {
  _border-left: 5px solid pink;
  _border-bottom: 5px solid pink;
  _border-right: 5px solid pink;
  _filter: chroma(color=pink);
}

.gDepFSArw,
.gDepSSArw {
  _border-bottom: 4px solid pink;
  _border-top: 4px solid pink;
  _border-right: 4px solid pink;
  _filter: chroma(color=pink);
}

.gDepFFArw,
.gDepSFArw {
  _border-bottom: 4px solid pink;
  _border-left: 4px solid pink;
  _border-top: 4px solid pink;
  _filter: chroma(color=pink);
}

/* Workaround for odd bug in old versions of Opera - no other browser needs this */

.glinediv {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1px;
}

.gantt-inputtable {
  max-width: 100%;
  max-height: 16px;
}

.gadditional {
  border: #efefef 1px solid;
}

.gadditional,
.gres,
.gdur,
.gcomp,
.gcost {
  text-align: center;
  width: 70px;
  max-width: 70px;
  min-width: 70px;
  font-size: 10px;
}

@media print {
  /* All your print styles go here */
  html,
  .gchartgrid {
    overflow: unset !important;
  }

  .glistgrid {
    float: none !important;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .gchartcontainer *::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .gchartcontainer * {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  /* Hide tool-tip */
  .JSGanttToolTip {
    display: none !important;
  }

  /* This is the default css, injected by the JSGantt.printChart(width,height); function -->
  @page {
    size: ${width}mm ${height}mm;
  }

  .gchartcontainer {
    width: ${width}mm;
  }
  <-- This will be injected by the JSGantt.printChart() function */
}

/* if using setUseSingleCell(1) the following is a suggested set of CSS3 styles to recreate the table grid - won't work on old browsers
.ggrouphour td,
.gmilehour td,
.gitemhour td			{ background-size: 19px 1px; background-image: linear-gradient(to left, #efefef, transparent 1px, transparent 18px); width: 100%; height: 19px; }
.ggroupday td,
.gmileday td,
.gitemday td			{ background-size: 19px 1px, 133px 1px; background-image: linear-gradient(to left, #efefef, transparent 1px, transparent 18px), linear-gradient(to left, #f7f7f7 39px, transparent 1px, transparent 92px); width: 100%; height: 19px; }
.ggroupweek td,
.gmileweek td,
.gitemweek td			{ background-size: 37px 1px; background-image: linear-gradient(to left, #efefef, transparent 1px, transparent 36px); width: 100%; height: 19px; }
.ggroupmonth td,
.gmilemonth td,
.gitemmonth td			{ background-size: 37px 1px; background-image: linear-gradient(to left, #efefef, transparent 1px, transparent 36px); width: 100%; height: 19px; }
.ggroupquarter td,
.gmilequarter td,
.gitemquarter td		{ background-size: 19px 1px; background-image: linear-gradient(to left, #efefef, transparent 1px, transparent 18px); width: 100%; height: 19px; }
*/

.gmain {
  height: 100%;

  display: flex;
  flex-direction: column;
  /* Allow resize */
  resize: horizontal;
}

.gmainright {
  overflow: hidden;
  flex: 1 1 auto;
}

.gmainleft {
  overflow: hidden;
  flex: 0 0 20%;
  min-width: 220px;
  /* Allow side to grow and shrink */
  flex: 1 0 auto;
}

.gtasktableh tr,
.gcharttable tr,
.gcharttableh tr,
.gtasktable tr {
  height: 22px !important;
}

.gtasktableouterwrapper {
  overflow: hidden;
}

.gtasktablewrapper {
  overflow-y: scroll;
  flex: auto;
  height: 100%;
}

.chartgrid {
  overflow: auto;
  flex: auto;
}

/* .gtasktable {
  margin-bottom: 14px;
} */

/* Printer friendly styles - we could use these all the time but they are not as pretty! */

/* note that "@media print" is not supported in IE6 or 7.  Fully patched IE8 should be OK */

@media print {
  .ggroupblack {
    height: 0px;
    border-top: 7px solid;
    border-color: #000000;
  }

  .gtaskblue {
    height: 0px;
    border-top: 13px solid;
    border-color: rgb(58, 132, 195);
  }

  .gtaskred {
    height: 0px;
    border-top: 13px solid;
    border-color: rgb(196, 58, 58);
  }

  .gtaskgreen {
    height: 0px;
    border-top: 13px solid;
    border-color: rgb(80, 193, 58);
  }

  .gtaskyellow {
    height: 0px;
    border-top: 13px solid;
    border-color: rgb(247, 228, 56);
  }

  .gtaskpurple {
    height: 0px;
    border-top: 13px solid;
    border-color: rgb(193, 58, 193);
  }

  .gtaskpink {
    height: 0px;
    border-top: 13px solid;
    border-color: rgb(249, 177, 245);
  }

  .gtaskbluecomplete,
  .gtaskredcomplete,
  .gtaskgreencomplete,
  .gtaskyellowcomplete,
  .gtaskpurplecomplete,
  .gtaskpinkcomplete {
    height: 0px;
    filter: alpha(opacity=40);
    opacity: 0.4;
    margin-top: -9px;
    border-top: 5px solid;
    border-color: #000000;
  }

  .ggroupblackcomplete {
    height: 0px;
    filter: alpha(opacity=80);
    opacity: 0.8;
    margin-top: -5px;
    border-top: 3px solid;
    border-color: #777777;
  }

  .gmainright,
  .gmainleft {
    overflow: hidden;
    width: auto;
  }

  table {
    page-break-after: auto;
  }

  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }

  td {
    page-break-inside: avoid;
    page-break-after: auto;
  }

  thead {
    display: table-header-group;
  }

  tfoot {
    display: table-footer-group;
  }
}

/* Fix for OSx trackpad hiding the scroll bars*/
.frame::-webkit-scrollbar {
  -webkit-appearance: none;
}

.frame::-webkit-scrollbar:vertical {
  width: 11px;
}

.frame::-webkit-scrollbar:horizontal {
  height: 11px;
}

.frame::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}

.frame::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 8px;
}

.gscrollbar-calculation-container {
  visibility: hidden;
  overflow: scroll;
  -ms-overflow-style: scrollbar;
  display: block;
}
